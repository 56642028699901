import { createContext, useContext, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import {
  IAdminThemeSettingContext,
  ISelectedThemePerComponent,
} from '../types/types.themeSetting';

const AdminThemeSettingContext = createContext<IAdminThemeSettingContext>({
  handleSelectedTheme: () => {},
  selectedThemePerComponent: { homePageCollectionTheme: 1 },
});

const AdminThemeSettingProvider = ({ children }: any) => {
  const [selectedThemePerComponent, setSelectedThemePerComponent] =
    useState<ISelectedThemePerComponent>({
      homePageCollectionTheme: 0,
    });
  const handleSelectedTheme = ({
    selectedValue,
  }: {
    selectedValue: { name: keyof ISelectedThemePerComponent; value: number };
  }) => {
    setSelectedThemePerComponent((prevState) => ({
      ...prevState,
      [selectedValue?.name]: selectedValue?.value,
    }));
  };
  return (
    <AdminThemeSettingContext.Provider
      value={{ selectedThemePerComponent, handleSelectedTheme }}
    >
      {children}
    </AdminThemeSettingContext.Provider>
  );
};
function useAdminThemeSetting() {
  const context = useContext(AdminThemeSettingContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('AdminThemeSetting'));
  }
  return context;
}

export { AdminThemeSettingProvider, useAdminThemeSetting };
