import { useQuery } from '@tanstack/react-query';
import { useMoralis } from 'react-moralis';

const useQueryFetching = () => {
  const { Moralis, chainId, account } = useMoralis();
  const useFetchData = ({
    variableToStore,
    cloudFunctionName,
    params,
    dependencies = [],
    initialData,
  }) => {
    const { data, status } = useQuery(
      [variableToStore, chainId, account, ...dependencies],

      async () => {
        return await Moralis.Cloud.run(cloudFunctionName, params);
      }
    );
    if (status === 'success') {
      return { data, status };
    } else {
      return { data: initialData, status };
    }
  };
  return { useFetchData };
};
export default useQueryFetching;
